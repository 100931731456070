import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import errorStyles from "../styles/error404.module.css";
import WindowSize from "../utilities/WindowSize";

import ErrorCode from "../images/components/Code404.svg";
import Earth from "../images/components/Earth.svg";
import Moon from "../images/components/Moon.svg";

function Star(props) {
  return (
    <div
      className={errorStyles.star}
      {...props}
      alt="contains some stars"
    ></div>
  );
}

const NotFound = () => {
  let starRows = [];
  for (let i = 0; i < 6; i++) {
    starRows.push(<Star key={`star${i.toString()}`} />);
  }

  let window = WindowSize();

  return (
    <Layout>
      <Metadata
        title="404"
        description="This is Hope Labs 404 page. If you find yourself here, go back to the homepage."
      />
      <section
        className={errorStyles.bgPurple}
        role="main"
        alt="background of empty space"
      >
        <div className={errorStyles.stars}>
          <div className={errorStyles.centralBody}>
            <img
              className={errorStyles.image404}
              src={ErrorCode}
              alt="404 error code "
            />
            <div
              className={`${errorStyles.centralBodyButton} ${errorStyles.errorButton}`}
            >
              <Link to="/">{window.width < 400 ? "Home" : "Take Me Home"}</Link>
            </div>
          </div>
          <div className={errorStyles.objects}>
            <img
              className={`${errorStyles.objectEarth} d-none d-sm-block`}
              src={Earth}
              alt="Simple spinning Earth."
            />
            <img
              className={`${errorStyles.objectMoon} d-none d-sm-block`}
              src={Moon}
              alt="Small static moon."
            />
          </div>
          <div className={errorStyles.glowingStars}>
            <div>{starRows}</div>
          </div>
        </div>
        {/* Thanks to Saleh Riaz for inspiring the creation of this page!*/}
      </section>
    </Layout>
  );
};

export default NotFound;
